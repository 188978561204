import React, { useState } from 'react';
import { useEmployeeDictionary } from '../../hooks/useEmployeeDictionary';
import Select from 'react-select';
import { SelectOption } from '@portal/frontend/utils';
import {
  TimeSheetTable,
  useAccessToken,
  useHiddenProjects,
  useTimesheetDate,
  WorkItemsDescriptionTable,
} from '@portal/frontend/react';
import { Container, Row } from 'react-bootstrap';

export default function EmployeeWorklogs() {
  const { employeeDictionary } = useEmployeeDictionary();

  const employeeDictionaryOptions = employeeDictionary?.map((employee) => ({
    label: employee.name,
    value: employee.id,
  }));

  const [employeeId, setEmployeeId] = useState(null);

  const {
    startDate,
    endDate,
    daysOfWeek,
    daysRows,
    setRangeDate,
    setPrevMonth,
    setNextMonth,
  } = useTimesheetDate();

  const { removeHiddenProjectsFromSearchParams } = useHiddenProjects();
  const {
    decodedToken: { role },
  } = useAccessToken();

  return (
    <>
      <Container className="p-4">
        <Row as={'main'}>
          <p className="m-1 mr-4">Select an employee</p>
          <Select<SelectOption, false>
            className="z-index-3"
            options={employeeDictionaryOptions}
            placeholder="Select ..."
            styles={{
              input: (provided) => ({
                ...provided,
                minWidth: '250px',
              }),
              menu: (provided) => ({
                ...provided,
                zIndex: 10,
                minWidth: '250px',
              }),
            }}
            onChange={(selectedOption) => {
              setEmployeeId(selectedOption?.value);
              removeHiddenProjectsFromSearchParams();
            }}
          />
        </Row>
      </Container>

      {employeeId && (
        <>
          <div className="d-flex justify-content-center mb-5">
            <TimeSheetTable
              employeeId={employeeId}
              daysOfWeek={daysOfWeek}
              startDate={startDate}
              endDate={endDate}
              daysRows={daysRows}
              setPrevMonth={setPrevMonth}
              setNextMonth={setNextMonth}
              setRangeDate={setRangeDate}
              authenticatedUserRole={role}
            />
          </div>

          <div className="d-flex justify-content-center pb-5">
            <WorkItemsDescriptionTable
              employeeId={employeeId}
              daysOfWeek={daysOfWeek}
              authenticatedUserRole={role}
            />
          </div>
        </>
      )}
    </>
  );
}
