import { ExtendedTextCell } from '@portal/frontend/react';
import {
  CellStyle,
  HeaderCell,
  NumberCell,
  TextCell,
  TimeCell,
} from '@silevis/reactgrid';
import { ChangeWeekCell } from './ChangeWeekCell';
import { ExtendedHeaderCell } from './ExtendedHeaderCellTemplate';
import { TextAreaCell } from './TextAreaCellTemplate';

export const getHeaderCell = (
  text: string,
  style?: CellStyle,
  className?: string,
): HeaderCell => {
  return { type: 'header', text, style, className };
};

export const getExtendedHeaderCell = (
  text: string,
  meta: {
    edit: boolean;
    projectName?: string;
    projectId?: string;
    workItemId?: string;
    workHourId?: string;
  },
  style?: CellStyle,
  className?: string,
  enableTooltip: boolean = false,
): ExtendedHeaderCell => {
  return {
    type: 'extendedHeader',
    text,
    meta,
    style,
    className,
    enableTooltip,
  };
};

export const getExtendedTextCell = (
  text: string,
  style?: CellStyle,
  className?: string,
): ExtendedTextCell => {
  return { type: 'extendedText', text, style, className };
};

export const getTextCell = (
  text: string,
  style?: CellStyle,
  className?: string,
  nonEditable?: boolean,
): TextCell => {
  return { type: 'text', style, text, className, nonEditable };
};

export const getNumberCell = (
  value: number,
  style?: CellStyle,
  className?: string,
  nonEditable?: boolean,
  hideZero?: boolean,
): NumberCell => {
  return { type: 'number', value, style, className, nonEditable, hideZero };
};

export const getPauseCell = (
  value: number,
  workHoursId?: string,
  style?: CellStyle,
  className?: string,
  nonEditable?: boolean,
  hideZero?: boolean,
): NumberCell & { workHoursId?: string } => {
  return {
    type: 'number',
    value,
    workHoursId,
    style,
    className,
    nonEditable,
    hideZero,
  };
};

export const getTimeCell = (
  time: Date,
  format?: Intl.DateTimeFormat,
  workHoursId?: string,
  style?: CellStyle,
  className?: string,
  nonEditable?: boolean,
): TimeCell & { workHoursId?: string } => {
  return {
    type: 'time',
    time,
    format: format,
    workHoursId: workHoursId,
    style,
    className,
    nonEditable,
  };
};

export const getChangeWeekCell = (
  nextWeek: boolean,
  onClick?: () => void,
): ChangeWeekCell => {
  return { type: 'changeWeek', nextWeek, onClick };
};

export const getTextAreaCell = (
  text: string,
  timetableEntryId?: string,
  isOpen?: boolean,
  style?: CellStyle,
  className?: string,
  nonEditable?: boolean,
): TextAreaCell => {
  return {
    type: 'textArea',
    timetableEntryId,
    text,
    style,
    className,
    nonEditable,
  };
};
