import { NavItemProps, UserRole } from '@portal/frontend/react';
import { AppRoutes } from '@portal/frontend/utils';

export const NAV_ITEMS: NavItemProps[] = [
  {
    path: AppRoutes.WORKITEMS,
    title: 'Workitems',
  },
  {
    path: AppRoutes.PROJECT_SETTINGS,
    title: 'Projects Settings',
  },
  {
    path: AppRoutes.REPORTS,
    title: 'Reports',
  },
  {
    path: AppRoutes.EMPLOYEES,
    title: 'Employees',
    excludeRoles: [UserRole.EMPLOYEE, UserRole.MANAGER],
  },
  {
    path: AppRoutes.LOCK_TIMES,
    title: 'Lock Time',
    excludeRoles: [UserRole.EMPLOYEE, UserRole.MANAGER],
  },
  {
    path: AppRoutes.BANK_HOLIDAYS,
    title: 'Bank Holidays',
  },
  {
    path: AppRoutes.EMPLOYEE_WORKLOGS,
    title: 'Employee worklogs',
    excludeRoles: [UserRole.EMPLOYEE],
  },
];
