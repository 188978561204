import { Id, Row } from '@silevis/reactgrid';
import moment from 'moment';
import {
  getExtendedHeaderCell,
  getHeaderCell,
  getNumberCell,
  getTextAreaCell,
  getTextCell,
} from '../templates/cellGetters';
import { Project } from '../interfaces/Project.interface';
import {
  DeveloperPortalCellTypes,
  WorkItemsRowCell,
} from '../interfaces/reactgrid.interface';
import { ProjectWorkDay } from '../interfaces/WorkDay.interface';
import { nonEditableStatuses } from '../enums/NonEditableStatus.enum';
import { shouldBeLocked } from './shouldBeLocked';
import { UserRole } from '../enums/UserRole.enum';

export type ProjectsModel = {
  projectWorkDay: ProjectWorkDay;
  projects: Project[];
  employeeLockDate: string;
  employeeRole: UserRole;
};

function getProjectRowId(day: moment.Moment, projectId): string {
  return `${day.toString()};${projectId}`;
}

function extractProjectId(rowId: Id): string {
  return rowId.toString().split(';')[1];
}

export const getWorkItemDescriptionRows = ({
  projectWorkDay,
  projects,
  employeeLockDate,
  employeeRole,
}: ProjectsModel): Row<DeveloperPortalCellTypes>[] => {
  const daysRows: Row<DeveloperPortalCellTypes> = {
    rowId: projectWorkDay.day.toString(),
    height: 30,
    cells: [
      {
        type: 'nonEditableChevron',
        text: projectWorkDay.day.format('ddd, MMM Do'),
        isExpanded: !projectWorkDay.hidden,
        className: 'style-0',
        hasChildren: true,
      },
      getTextCell('ID', {}, 'style-0', true),
      getTextCell('Hours', {}, 'style-0', true),
      getTextCell('Outcome', {}, 'style-0', true),
    ],
  };

  const projectsRows: Row<DeveloperPortalCellTypes>[] = projects.map(
    (project) => {
      return {
        rowId: getProjectRowId(projectWorkDay.day, project.id),
        height: 35,
        cells: [
          {
            type: 'nonEditableChevron',
            text: project.name,
            isExpanded: !(
              projectWorkDay.hiddenProjectsIds.findIndex((index) =>
                index.includes(project.id),
              ) > -1
            ),
            className: 'style-1',
            hasChildren: true,
            parentId: projectWorkDay.day.toString(),
            indent: 1,
          },
          getTextCell('', {}, 'style-1', true),
          getTextCell('', {}, 'style-1', true),
          getTextCell('', {}, 'style-1', true),
        ],
      };
    },
  );

  const workItemRows = (
    projectRow: Row<DeveloperPortalCellTypes>,
    shouldHideProjects: boolean,
  ): Row<DeveloperPortalCellTypes>[] => {
    const project = projects.filter(
      (project) =>
        projectRow.rowId === getProjectRowId(projectWorkDay.day, project.id),
    )[0];
    const workItems = project.workItems.map<Row<DeveloperPortalCellTypes>>(
      (workItem) => {
        const timetableEntry = workItem.timetableEntries.find(
          (timeTableEntry) =>
            moment(timeTableEntry.date).isSame(projectWorkDay.day, 'date'),
        );
        const descriptionCell = (): WorkItemsRowCell => {
          if (
            nonEditableStatuses.some((status) => status === workItem.status)
          ) {
            return getHeaderCell(`${timetableEntry?.description || ''}`, {
              background: 'black',
            });
          }
          const isTimeLoggingLocked = shouldBeLocked(
            moment(employeeLockDate, 'DD-MM-YYYY').toDate(),
            moment(timetableEntry?.date).toDate(),
            employeeRole,
          );
          return getTextAreaCell(
            timetableEntry?.description || '',
            timetableEntry?.id,
            true,
            {},
            isTimeLoggingLocked ? 'non-editable-cell' : '',
            isTimeLoggingLocked,
          );
        };
        const hours = workItem.timetableEntries.find((timeTableEntry) =>
          moment(timeTableEntry.date).isSame(projectWorkDay.day, 'date'),
        ).hours;
        return {
          rowId: `${projectRow.rowId}-${workItem.id}`,
          height: 30,
          cells: [
            getExtendedHeaderCell(
              workItem.name,
              {
                edit: !shouldBeLocked(
                  moment(employeeLockDate, 'DD-MM-YYYY').toDate(),
                  moment(timetableEntry?.date).toDate(),
                  employeeRole,
                ),
                projectName: project.name,
                projectId: project.id,
                workItemId: workItem.id,
                workHourId: timetableEntry.id,
              },
              { paddingLeft: '3.2em' },
              'style-2',
              true,
            ),
            getExtendedHeaderCell(
              workItem.extId,
              {
                edit: false,
              },
              {},
              'style-2',
            ),
            getNumberCell(hours, {}, 'style-2', true, false),
            descriptionCell(),
          ],
        };
      },
    );
    return shouldHideProjects ? [projectRow] : [projectRow, ...workItems];
  };
  return projectWorkDay.hidden
    ? [daysRows]
    : [
        daysRows,
        ...projectsRows.flatMap((projectRow) =>
          workItemRows(
            projectRow,
            projectWorkDay.hiddenProjectsIds.includes(
              extractProjectId(projectRow.rowId),
            ),
          ),
        ),
      ];
};
